import './App.css';
import * as React from "react";
import BackgroundChanger from "./BackgroundChanger";

const App = _ => {

    return (
        <div>
            <BackgroundChanger />
        </div>
      );
}


export default App;
